
button:focus, input:focus {
  outline:0;
}

/* LARGE BLUE BUTTON WITH NO TEXT */
.large_blue_button_no_text {
  border-radius: 12px;
  border: 0px;
  background-color: var(--color-blue60);
  color: var(--color-blue5);
}
.large_blue_button_no_text:hover{
  background-color: var(--color-blue80);
  color: var(--color-blue5);
}
.large_blue_button_no_text:focus{
  background: var(--color-blue70);
  color: var(--color-blue5);
  box-shadow:
  0px 0px 0 1px var(--color-blue5),
  0px -0px 0 1px var(--color-blue5),
  -0px 0px 0 1px var(--color-blue5),
  -0px 0px 0 1px var(--color-blue5),
  2px 2px 0 2px var(--color-blue40),
  2px -2px 0 2px var(--color-blue40),
  -2px 2px 0 2px var(--color-blue40),
  -2px -2px 0 2px var(--color-blue40);
}
.large_blue_button_no_text:active{
  background: var(--color-blue70);
  color: var(--color-blue5);
}
.large_blue_button_no_text:disabled{
  background: var(--color-grey50);
  color: var(--color-white100);
}


/* TRANSPARENT BUTTON WITH BLUE FONT*/
.transparent_blue_button {
  background-color: transparent;
  font-weight: 600;
  border: 0px;
  padding: 0px;
  color: #0046b9;
}
.transparent_blue_button:hover {
  color: #003081;
}
.transparent_blue_button:focus {
  color: #003081;
}
.transparent_blue_button:active {
  color: #0056E3;
}
.transparent_blue_button:disabled {
  color: #C1C1C1;
}

/* TRANSPARENT BUTTON WITH MINT FONT*/
.transparent_mint_button {
  background-color: transparent;
  font-weight: 600;
  border: 0px;
  padding: 0px;
  color: #049080;
}
.transparent_mint_button:hover {
  color: #037366;
}
.transparent_mint_button:focus {
  color: #037366;
}
.transparent_mint_button:active {
  color: #05b9a5;
}
.transparent_mint_button:disabled {
  color: #C1C1C1;
}

/* TRANSPARENT BUTTON WITH WHITE FONT*/
.transparent_white_button {
  background-color: transparent;
  border: 0px;
  padding: 0px;
  color: var(--color-blue5);
  display: flex;
  align-items: center;
}

/* TRANSPARENT BUTTON WITH GRAY FONT*/
.transparent_gray_button {
  background-color: transparent;
  border: 0px;
  padding: 0px;
  color: #B3B3B3;
}
.transparent_gray_button:hover {
  color: #8C8C8C;
}
.transparent_gray_button:focus {
  color: #8C8C8C;
}
.transparent_gray_button:active {
  color: #8C8C8C;
}

/* ROUND MINT BUTTON WITH WHITE FONT */
.round_button {
  margin-top: auto;
  margin-bottom: auto;
  height: 25px;
  min-width: 25px;
  display:inline-block;
  text-align: center;
  line-height: 0px;
  border-radius: 50%;
  font-size: 14px;
  box-shadow: none !important;
}
.round_button_L {
  margin-top: auto;
  margin-bottom: auto;
  height: 40px;
  min-width: 40px;
  display:inline-block;
  text-align: center;
  line-height: 0px;
  border-radius: 50%;
  font-size: 14px;
  box-shadow: none !important;
}


.r_button_primary {
  color: var(--color-white100);
  background-color: var(--color-blue60);
  border: none;
}
.r_button_secondary {
  color: var(--color-blue100);
  background-color: var(--color-grey10);
  outline: 0 none !important;
  border: 1px solid var(--color-grey40);
}

.primary_line {
	border: 2px solid var(--color-blue60);
}
.secondary_line {
	border: 2px solid var(--color-grey40);
}
hr{
  margin: auto 0px;
  opacity: revert;
  width: 25%;
}
/* ROUND WHITE BUTTON WITH MINT FONT */

/* BLUE BUTTON WITH WHITE FONT, WITHOUT RADIUS BORDER*/
.blue_button_no_radius {
  color: #FFF;
  background-color: #0046B9;
  border: 2px solid #0046B9;
}
.blue_button_no_radius:hover{
  background-color: #003081;
  border: 2px solid #003081;
}
.blue_button_no_radius:focus{
  background-color: #003081;
  border: 2px solid #003081;
}
.blue_button_no_radius:active{
  background-color: #0056E3;
  border: 2px solid #0056E3;
}
.blue_button_no_radius:disabled{
  background-color: #C1C1C1;
  border: 2px solid #C1C1C1;
}

/* WHITE BUTTON WITH BLUE FONT, WITHOUT RADIUS BORDER*/
.white_button_no_radius {
  color: #FFF;
  color: #001487;
  background-color: #FFF;
  border: 2px solid #FFF;
}
.white_button_no_radius:hover{
  color: #0045b9;
  background-color: #FFF;
  border: 2px solid #FFF;
}
.white_button_no_radius:focus{
  color: #0054E2;
  background-color: #FFF;
  border: 2px solid #FFF;
}
.white_button_no_radius:active{
  color: #001487;
  background-color: #FFF;
  border: 2px solid #FFF;
}
.white_button_no_radius:disabled{
  color: #A8A8A8;
  background-color: #FFF;
  border: 2px solid #FFF;
}

/* DEFAULT CURSOR */
.default_cursor:hover {
  cursor: default;
}

/* GRAY BUTTON WITH DARK GRAY CONTENT*/
.gray_button_with_dark_content {
  color: #B3B3B3;
  background-color: #EEEEEE;
}
.gray_button_with_dark_content:hover{
  color: #FFFFFF;
}
.gray_button_with_dark_content:focus{
  color: #FFFFFF;
}


/* ****************** */
/* NEW BUTTONS STYLES */
/* ****************** */

/*LARGE BUTTON*/
.large_button{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.0;
  padding: 22px 24px;
  height: 64px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0px 1px 2px 0px rgba(3, 0, 69,0.15); /* --color-blue90 with alpha 0.15 */
}

/*MEDIUM BUTTON*/
.medium_button{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.0;
  padding: 16px 24px;
  height: 50px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0px 1px 2px 0px rgba(3, 0, 69,0.15);
}

/*SMALL BUTTON*/
.small_button{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.0;
  padding: 14px 16px;
  height: 39px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0px 1px 2px 0px rgba(3, 0, 69,0.15);
}


.text_icon > span{
  margin-right: 16px !important;
}
.icon_text > span{
  margin-left: 16px !important;
}
.icon {
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  height: 48px;
  width: 64px;
}
.small_icon {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
  border-radius: 4px;
  height: 48px;
  width: 48px;
}
.icon > svg{
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
}
.small_icon > svg{
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
}

h1 svg, h2 svg{
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  margin-right: 12px;
}


.icon_primary{
  background-color: var(--color-blue60);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.icon_primary > svg g, .icon_primary > svg path {
  fill: var(--color-blue5);
  stroke: var(--color-blue5);
}
.icon_primary:hover{
  background-color: var(--color-blue80);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.icon_primary:hover > svg g, .icon_primary:hover > svg path {
  fill: var(--color-blue5);
  stroke: var(--color-blue5);
}
.icon_primary:active{
  background-color: var(--color-blue70);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
}
.icon_primary:active > svg g, .icon_primary:active > svg path{
  fill: var(--color-blue5);
  stroke: var(--color-blue5);
}
.icon_primary:disabled{
  background-color: var(--color-grey50);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
}
.icon_primary:disabled > svg g, .icon_primary:disabled > svg path{
  fill: var(--color-white100);
  stroke: var(--color-white100);
}
.icon_primary:focus{
  background-color: var(--color-blue70);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
  box-shadow:
      0px 0px 0 1px var(--color-blue5),
      0px -0px 0 1px var(--color-blue5),
      -0px 0px 0 1px var(--color-blue5),
      -0px 0px 0 1px var(--color-blue5),
      2px 2px 0 2px var(--color-blue40),
      2px -2px 0 2px var(--color-blue40),
      -2px 2px 0 2px var(--color-blue40),
      -2px -2px 0 2px var(--color-blue40);
  z-index: 3;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.icon_primary:focus > svg g, .icon_primary:focus > svg path{
  fill: var(--color-blue5);
  stroke: var(--color-blue5);
}


/* FIND COLORS to TRANSFORM USING https://angel-rs.github.io/css-color-filter-generator/ */
.transparent_white_button img {
  padding: 0px;
  margin: 0px;
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%);
}
.button_primary img{
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%);
}
.button_primary:disabled img{
  filter: brightness(0) saturate(100%) invert(62%) sepia(4%) saturate(0%) hue-rotate(292deg) brightness(85%) contrast(83%);
}

.button_secondary img{
  filter: brightness(0) saturate(100%) invert(14%) sepia(96%) saturate(3129%) hue-rotate(218deg) brightness(106%) contrast(105%);
}
.button_secondary:hover img{
  filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
}
.button_secondary:focus img{
  filter: brightness(0) saturate(100%) invert(14%) sepia(96%) saturate(3129%) hue-rotate(218deg) brightness(106%) contrast(105%);
  z-index: 2;
}

.img_primary{
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%);
}
.img_secondary{
  filter: brightness(0) saturate(100%) invert(14%) sepia(96%) saturate(3129%) hue-rotate(218deg) brightness(106%) contrast(105%);
}


.tagScroll{
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
}
.tagContainer {
  padding-bottom: 8px;
  width: max-content;
}
.tagDiv{
  display: inline-block;
  margin: auto 4px;
}

.tag_S {
  height: 40px;
  width: max-content;
  margin-top: 8px;
  border-radius: 24px;
}
.tag_S > span {
  font-size: 18px;
  margin-left: 16px;
  margin-right: 12px;
  line-height: 1;
}
.tag_S > svg {
  margin: auto 8px auto 12px;
  height: 24px;
  width: 24px;
}

.tag_M {
  height: 48px;
  width: max-content;
  margin-top: 8px;
  border-radius: 24px;
}
.tag_M > span {
  font-size: 18px;
  margin-left: 24px;
  margin-right: 16px;
  line-height: 1;
}
.tag_M > svg {
  margin: auto 8px auto 16px;
  height: 32px;
  width: 32px;
}

.tag_primary{
  background-color: var(--color-grey10);
  color: var(--color-blue100);
  border: 1px solid var(--color-grey40);
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.tag_primary > svg g {
  fill: var(--color-blue100);
  stroke: var(--color-blue100);
}

.tag_primary:disabled{
  background-color: var(--color-grey50);
  color: var(--color-white100);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
}

.tag_primary:hover{
  background-color: var(--color-grey20);
  color: var(--color-blue100);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.tag_primary:focus{
  background-color: var(--color-grey20);
  color: var(--color-blue100);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
  box-shadow:
    0px 0px 0 1px var(--color-white100),
    0px -0px 0 1px var(--color-white100),
    -0px 0px 0 1px var(--color-white100),
    -0px 0px 0 1px var(--color-white100),
    2px 2px 0 2px var(--color-blue40),
    2px -2px 0 2px var(--color-blue40),
    -2px 2px 0 2px var(--color-blue40),
    -2px -2px 0 2px var(--color-blue40);
  z-index: 3;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.tag_primary:hover > svg g {
  fill: var(--color-blue100);
  stroke: var(--color-blue100);
}

.tag_secondary{
  background-color: var(--color-blue60);
  color: var(--color-blue10);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.tag_secondary:disabled{
  background-color: var(--color-grey50);
  color: var(--color-white100);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
}

.tag_secondary:hover{
  background-color: var(--color-blue70);
  color: var(--color-blue10);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.tag_secondary:focus{
  background-color: var(--color-blue70);
  color: var(--color-blue10);
  border: none;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
  box-shadow:
    0px 0px 0 1px var(--color-white100),
    0px -0px 0 1px var(--color-white100),
    -0px 0px 0 1px var(--color-white100),
    -0px 0px 0 1px var(--color-white100),
    2px 2px 0 2px var(--color-blue40),
    2px -2px 0 2px var(--color-blue40),
    -2px 2px 0 2px var(--color-blue40),
    -2px -2px 0 2px var(--color-blue40);
  z-index: 3;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.tag_secondary:hover > svg g {
  fill: var(--color-blue10);
  stroke: var(--color-blue10);
}


/*PRIMARY BLUE BUTTON*/
.button_primary {
  background: var(--color-blue60);
  color: var(--color-blue5);
  border: none;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.button_primary:hover{
  background: var(--color-blue80);
  color: var(--color-blue5);
  z-index: 2;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.button_primary:active{
  background: var(--color-blue70);
  color: var(--color-blue5);
}
.button_primary:disabled{
  background: var(--color-grey50);
  color: var(--color-white100);
}
.button_primary:focus{
  background: var(--color-blue70);
  color: var(--color-blue5);
  box-shadow:
  0px 0px 0 1px var(--color-blue5),
  0px -0px 0 1px var(--color-blue5),
  -0px 0px 0 1px var(--color-blue5),
  -0px 0px 0 1px var(--color-blue5),
  2px 2px 0 2px var(--color-blue40),
  2px -2px 0 2px var(--color-blue40),
  -2px 2px 0 2px var(--color-blue40),
  -2px -2px 0 2px var(--color-blue40);
  z-index: 2;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}


/*SECONDARY BLUE BUTTON*/
.button_secondary{
  background: var(--color-white100);
  color: var(--color-blue60);
  border: 1px solid var(--color-blue60);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.button_secondary:hover{
  background: var(--color-blue60);
  color: var(--color-blue5);
  border: 1px var(--color-blue60);
  z-index: 2;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.button_secondary:active{
  background: var(--color-grey10);
  color: var(--color-blue70);
  border: 1px solid var(--color-blue70);
}
.button_secondary:disabled{
  background: var(--color-blue70);
  color: var(--color-blue5);
  border: 1px solid var(--color-blue5);
}
.button_secondary:focus{
  background: var(--color-grey10);
  color: var(--color-blue70);
  border: 1px solid var(--color-blue70);
  box-shadow:
  0px 0px 0 1px var(--color-blue5),
  0px -0px 0 1px var(--color-blue5),
  -0px 0px 0 1px var(--color-blue5),
  -0px 0px 0 1px var(--color-blue5),
  2px 2px 0 2px var(--color-blue40),
  2px -2px 0 2px var(--color-blue40),
  -2px 2px 0 2px var(--color-blue40),
  -2px -2px 0 2px var(--color-blue40);
  z-index: 2;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

/*WHITE BUTTON*/
.button_tertiary{
  background: var(--color-white100);
  color: var(--color-blue60);
  border: 1px dashed var(--color-grey50);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.button_tertiary:hover{
  background: var(--color-blue60);
  color: var(--color-blue5);
  border: 1px solid var(--color-blue60);
  z-index: 2;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.button_tertiary:active{
  background: var(--color-grey10);
  color: var(--color-blue70);
  border: 1px dashed var(--color-blue70);
}
.button_tertiary:disabled{
  background: var(--color-grey10);
  color: var(--color-grey50);
  border: 1px dashed var(--color-grey40);
}
.button_tertiary:focus{
  background: var(--color-grey10);
  color: var(--color-blue70);
  border: 1px dashed var(--color-blue70);
  box-shadow:
  0px 0px 0 1px var(--color-blue5),
  0px -0px 0 1px var(--color-blue5),
  -0px 0px 0 1px var(--color-blue5),
  -0px 0px 0 1px var(--color-blue5),
  2px 2px 0 2px var(--color-blue40),
  2px -2px 0 2px var(--color-blue40),
  -2px 2px 0 2px var(--color-blue40),
  -2px -2px 0 2px var(--color-blue40);
  z-index: 2;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.titleComponent p {
  font-size: 16px;
}
input {
  margin-top: 8px;                /*label     */
  margin-bottom: 4px;             /*hint      */
  color: var(--color-blue100);
  font-family: 'bdrText', 'Courier New', Courier, monospace;
  font-size: 16px !important;
  height: 48px;
  border-radius: 2px;
  padding: 0 16px !important;
  background-color: var(--color-white100);
  border: none !important;
  box-shadow:
     0px 0px 0 1px var(--color-grey60),
     0px -0px 0 1px var(--color-grey60),
     -0px 0px 0 1px var(--color-grey60),
     -0px 0px 0 1px var(--color-grey60) !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
input::placeholder {
  color: var(--color-grey60);
}

input:hover {
  background-color: var(--color-white100);
  border: none !important;
  box-shadow:
    0px 0px 0 1px var(--color-grey80),
    0px -0px 0 1px var(--color-grey80),
    -0px 0px 0 1px var(--color-grey80),
    -0px 0px 0 1px var(--color-grey80) !important;
  z-index: 2;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
input:hover::placeholder {
  color: var(--color-grey60);
}

input:focus {
  background-color: var(--color-white100);
  border: 1px solid var(--color-blue60) !important;
  box-shadow:
    0px 0px 0 1px var(--color-blue5),
    0px -0px 0 1px var(--color-blue5),
    -0px 0px 0 1px var(--color-blue5),
    -0px 0px 0 1px var(--color-blue5),
    2px 2px 0 2px var(--color-blue40),
    2px -2px 0 2px var(--color-blue40),
    -2px 2px 0 2px var(--color-blue40),
    -2px -2px 0 2px var(--color-blue40) !important;
  caret-color: var(--color-blue60);
  z-index: 2;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
input:focus::placeholder {
  color: var(--color-grey40);
}

input:active {
  background-color: var(--color-white100);
  border: none !important;
  border: 1px solid var(--color-blue60) !important;
  caret-color: var(--color-blue60);
}
input:active::placeholder {
  color: var(--color-grey40);
}

input:disabled {
  background-color: var(--color-grey10);
  border: none !important;
  border: 1px solid var(--color-grey60);
  color: var(--color-grey60);
}


input[type="radio"] {
  height: 12px;
  width: 12px;
  padding:8px;
  box-shadow: none !important;
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
}
input[type="radio"]:focus {
  box-shadow: none !important;
}
.radioButtonGroup  input[type="radio"]{
  margin-right: 8px;
  vertical-align: text-bottom;
}
.radioButtonGroup {
  margin-top: 12px;
}

.radioButtonGroup p span{
  font-size: 16px;
  line-height: 1.0;
  padding-left: 10px;
  margin: auto;
}
.oneRadioBtn:first-child {
  justify-content: flex-start;
  text-align: center;
  margin-bottom: 16px;
}


.p-dropdown {
  margin-top: 8px;                /*label     */
  margin-bottom: 4px;             /*hint      */
  color: var(--color-blue100) !important;
  font-family: 'bdrText', 'Courier New', Courier, monospace !important;
  font-size: 16px !important;
  height: 48px !important;
  border-radius: 2px !important;
  padding: 0 16px;
  background-color: var(--color-white100);
  outline: revert;
  border: 1px solid var(--color-grey60) !important;
  box-shadow: none;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.p-inputtext {
  color: var(--color-blue100) !important;
  display: inline-block;
  line-height: 48px;
  margin-top: 8px;                /*label     */
  margin-bottom: 4px;             /*hint      */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.p-placeholder {
  color: var(--color-grey60) !important;
}
.p-dropdown-trigger-icon {
  color: var(--color-blue60);
  height: 16px !important;
  width: 16px !important;
}
.p-dropdown:hover {
  outline: 0 !important;
  border: 0 !important;
  border-radius: 2px !important;
  box-shadow:
    0px 0px 0 1px var(--color-grey80),
    0px -0px 0 1px var(--color-grey80),
    -0px 0px 0 1px var(--color-grey80),
    -0px 0px 0 1px var(--color-grey80) !important;
  z-index: 2;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.p-focus, .p-focus:hover {
  border-radius: 2px !important;
  border: 1px solid var(--color-grey80) !important;
  box-shadow:
  0px 0px 0 1px var(--color-blue5),
  0px -0px 0 1px var(--color-blue5),
  -0px 0px 0 1px var(--color-blue5),
  -0px 0px 0 1px var(--color-blue5),
  2px 2px 0 2px var(--color-blue40),
  2px -2px 0 2px var(--color-blue40),
  -2px 2px 0 2px var(--color-blue40),
  -2px -2px 0 2px var(--color-blue40) !important;
  z-index: 2;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.p-disabled{
  background-color: var(--color-grey10);
  border: 1px solid var(--color-grey60);
  color: var(--color-grey60);
}
p-disabled pi-chevron-down {
  color: var(--color-grey60);
}

.p-checkbox-box, .p-checkbox {
  margin: auto 0px;
  border-radius: 4px !important;
  height: 20px !important;
  width: 20px !important;
  align-items: center !important;
}



li.p-dropdown-item {
  font-family: 'bdrText', 'Courier New', Courier, monospace !important;
  font-size: 16px !important;
  height: 48px !important;
  background-color: var(--color-white100) !important;
  color: var(--color-blue100) !important;
  border: 1px solid var(--color-grey60) !important;
  box-shadow: 0px 1px 3px 1px rgba(0,0,41,0.15) !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
li.p-highlight{
  background-color: var(--color-blue5) !important;
  color: var(--color-blue60) !important;
  border: 1px solid var(--color-blue60) !important;
}
li.p-dropdown-item:hover {
  background-color: var(--color-grey10) !important;
  color: var(--color-blue100) !important;
  border: 1px solid var(--color-grey60) !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

/* WHEN PRIMEREACT DROPDOWN IS FOCUSED, NOT SOLVED TODO
li.p-dropdown-item, .p-highlight {
     z-index: 1002;
     background-color: var(--color-grey10) !important;
     color: var(--color-blue100) !important;
     border: 1px solid var(--color-grey60) !important;
     box-shadow:
     1px 1px 0 1px var(--color-blue5),
     1px -1px 0 1px var(--color-blue5),
     -1px 1px 0 1px var(--color-blue5),
     -1px 1px 0 1px var(--color-blue5),
     4px 4px 0 4px var(--color-blue40),
     4px -4px 0 4px var(--color-blue40),
     -4px 4px 0 4px var(--color-blue40),
     -4px -4px 0 4px var(--color-blue40) !important;
}
*/
.p-dropdown-panel .p-dropdown-items {
  padding: 0px !important;
}


.spinner {
  margin: 20px;
}
.tabContent > .spinner {
  margin: auto;
  margin-left: 20px;
}



/**************** TOOLTIP*/
.tooltip-wrap {
  padding-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}
.tooltip-wrap > img {
  height: 24px;
  width: 24px;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: absolute;
  bottom: 40px;
  -webkit-transform: translateX(55%);
  transform: translateX(-55%);
  color: var(--color-blue5);
  background-color: var(--color-blue60);
  line-height: 1.0;
  padding: 16px;
  z-index: 2;
  vertical-align: middle;
  width: max-content;
  max-width: 550px;
}
.tooltip-wrap .tooltip-content:after {
  content: '';
  width: 0px;
  height: 0px;
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-top: 10px solid var(--color-blue60);
  position: absolute;
  bottom: -20px;
  left: calc(56% - 25px);
}
.tooltip-wrap:hover .tooltip-content {
  display: inline-block;
}
.tooltip-content span {
  font-size: 16px !important;
  display: inline-block;
}


::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border: 1px solid var(--color-grey40);
}
::-webkit-scrollbar-track {
  width: 12px;
  padding: 2px;
  background-color: var(--color-grey20);
}
::-webkit-scrollbar-thumb {
  background-color: var(--color-grey60);
  border-radius: 6px;
  border: 2px solid var(--color-grey20);;
  background-clip: content-box;
}
* {
  scrollbar-width: 8px;
  scrollbar-color: var(--color-grey60) var(--color-grey20);
}

/*
body::-webkit-scrollbar:hover {
    background: lightyellow;
}
*/


/*Toast DESIGN: */
.Toastify__toast{
  padding: 15px !important;
}
.Toastify__toast--error{
  background: var(--color-red60) !important;
  color: var(--color-red5) !important;
}
.Toastify__toast--info{
  background: var(--color-blue60) !important;
  color: var(--color-blue5) !important;
}

#progressBarDiv{
  z-index: 998;
  background-color: var(--color-grey10);
  border: 1px solid var(--color-grey40);
  border-left: none;
  border-right: none;
}
.reading-progress-bar {
  background-color: var(--color-green60);
  z-index: 999;
  height: 8px;
  width: 100%;
  box-shadow: 0px 1px 3px rgba(0,0,41,0.15);
}
