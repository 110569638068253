.footer {
	height: 140px;
}
.footerContent {
  height: 100%;
  max-width: 100% !important;
  margin-right: 32px !important;
  margin-left: 32px !important;
  display: flex;
  justify-content: space-between !important;
  flex-direction: row !important;
  padding-top: 40px;
  padding-bottom: 25px;
}
#footerLogo {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
#footerLogo img {
  height: 22px;
}

.footerContent #versionInfo p {
	font-size: 18px;
}

.footerContent button {
	border: 1px solid;
	color: var(--color-blue5);
	background-color: transparent;
}

#impressumPopUp .oneComponent {
  padding-bottom: 32px;
}

#versionInfo {
  justify-content: left;
	margin-bottom:2px;
	font-weight: bold;
	white-space: nowrap;
}

#impressum-modal * {
  color: var(--color-blue100) !important;
}

#informationFooter {
  width: 80%;
  display: flex;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
}

#informationFooter > * {
  display: inline-flex;
  font-size: 16px;
  margin-right: 12px;
  padding: 8px 8px;
}

#informationFooter > *:last-child {
  margin-right: 0px;
}

#DPSLink, #licenses {
  overflow: hidden;
}

@media only screen and (max-width: 1248px){
  #informationFooter > * {
    font-size: 12px;
  }
}

@media only screen and (max-width: 860px){
  #informationFooter > * {
    font-size: 10px;
  }
}