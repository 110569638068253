#deactivatePopUp {
  padding: 21px 30px 30px 30px;
}
#deactivatePopUp hr {
  height: 4px;
  border: 0;
  box-shadow: 0 10px 10px -10px #f2f2f2 inset;
}
#impressum-modal{
  max-width: 756px;
} 
#impressumPopUp hr {
  height: 1px;
  border: 0;
  box-shadow: none;
  margin: 10px 0px;
}
/****************** ONE COMPONENT & PARALEL COMPONENT DESIGN: */
#deactivatePopUp .oneComponent {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}
#deactivatePopUp .titleComponent {
  margin-bottom: 10px;
}
.titleComponent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.titleComponent .requiredField{
  margin-left: 2px;
}

#deactivatePopUp input {
  height: 45px;
  background: white;
  padding: 9px  20px 9px 20px;
}
#deactivatePopUp input:disabled {
  height: 45px;
  background: white;
  padding: 9px  20px 9px 20px;
  color: #B3B3B3;
  border: 1px solid #EEEEEE;
}
#deactivatePopUp .disabledInput {
  color: var(--color-grey60);
}
.infoComponent {
  margin-top: 9px;
}
.parallelComponent, fieldset {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.input-field fieldset {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
/****************** WARRNING MESSAGES: */
.warningMessage {
  margin-bottom: 32px !important;
  margin-top: -32px !important;
  padding-left: 30px;
  color: var(--color-red60) !important;
}

/*red or default color --> depending of error! */
.oneComponent .warningErrorLabel {
  color: var(--color-red60) !important;
}
.oneComponent .defaultStateLabel {
  color: black;
}
.oneComponent .warningErrorInput {
  border: 1px solid var(--color-red60) !important;
}

.p-dropdown.warningErrorInput .p-dropdown-trigger {
  color: var(--color-red60);
}

.p-autocomplete.warningErrorInput > .p-autocomplete-input{
  color: var(--color-red60);
  border: 1px solid var(--color-red60) !important;
}

.modal{
  display: flex !important;
  align-items: center !important;
}
.modal-title h5{
  padding-bottom: 8px;
  font-family: 'bdrText', 'Courier New', Courier, monospace;
  font-weight: 600;
  font-size: 20px;
  color: var(--color-blue100);
}
.modal-header {
  padding: 20px 30px 0px 30px;
  border-bottom: 2px solid var(--color-blue100) !important;
}
.modal-body {
  padding: 10px;
}

.modal-header > h5 {
  font-size: 18px;
}

.modal-body > .parallelComponent{
  margin-left: 30px;
}
.modal-body .oneComponent{
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 16px;
}

.modal-body .buttonPart button{
  margin-left: 0px;
}
.modal-body .buttonPart {
  margin-top: 48px !important;
  margin-left: 30px;
}