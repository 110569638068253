
@import "Colors.css";
@import "Fonts.css";

.body {
    min-height: calc(100vh - 272px); /* minus the height of the footer */
}

@media only screen and (max-width: 767px){
  p.h0{  font-size: 50px !important;}
  h1 { font-size: 36px; }
  h3 { font-size: 30px; }
}

@media only screen and (min-width: 768px){
  p.h0{  font-size: 80px !important; }
  h1 { font-size: 48px; }
  h3 {font-size: 36px; }
}

p.h0 {
    font-family: 'bdrText', 'Courier New', Courier, monospace;
    margin: 0px;
    font-weight: initial;
    padding-bottom: 0px !important;
}

h1 {
    font-family: 'bdrText', 'Courier New', Courier, monospace;
    font-weight: bold;
    margin: 0px;
}

h2 {
    font-family: 'bdrText', 'Courier New', Courier, monospace;
    font-size: 48px;
    margin: 0px;
}

h3 {
    font-family: 'bdrText', 'Courier New', Courier, monospace;
    font-weight: bold;
    margin: 0px;
}

h4 {
    font-family: 'bdrText', 'Courier New', Courier, monospace;
    font-size: 36px;
    margin: 0px;
}

h5 {
    font-family: 'bdrText', 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 30px;
    margin: 0px;
}

h5.small {
    font-family: 'bdrText', 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 18px;
    margin: 0px;
}

h5.semibold {
    font-family: 'bdrText', 'Courier New', Courier, monospace;
    font-weight: 600;
    font-size: 18px;
    margin: 0px;
}

p {
    font-family: 'bdrText', 'Courier New', Courier, monospace;
    margin: 0px;
}

p.regular {
    font-family: 'bdrText', 'Courier New', Courier, monospace;
    font-size: 14px;
    margin: 0px;
}

body, a, span, div, button, input, form {
    font-family: 'bdrText', 'Courier New', Courier, monospace;
}


.background-color {
  background-color: var(--color-blue100);
  color: var(--color-blue5);
}

.header-color {
  background-color: var(--color-blue90);
  color: var(--color-blue5);
}

.body-color {
  background-color: var(--color-grey10);
  color: var(--color-blue60);
}

.emphasis-color {
  background-color: var(--color-grey10);
  color: var(--color-blue60);
}

.disabled-color {
  background-color: var(--color-grey10);
  color: var(--color-grey60);
}

.grid-color{
  background-color: var(--color-grey10);
  color: var(--color-blue100);
}

.svg-inherit-color {
  color: var(--color-white100);
}

.background-color-white {
  background-color: #f6f6f6;
}
.color-white {
  color: #ffffff;
}

.background-color-blue {
  background-color: #049080;
}
.color-blue {
  color: #049080;
}
.second-header-color{
  background: #004aa3;
}
.background-color-dark {
  background-color: #001487;
}
.color-dark {
  color: #001487;
}

.background-color-grey {
  background-color: #EEEEEE;
}
.color-grey {
  color: #EEEEEE;
}

.background-color-darkGrey {
  background-color: #B3B3B3;
}
.color-darkGrey {
  color: #B3B3B3;
}

.background-gradient-dark-blue {
  background-image: linear-gradient(to right, #0154B7, #05B9A5);
}

.content {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contentHorizontal {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
	flex-wrap: wrap;
  justify-content: space-between;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}
