/* Extra small Devices / Tablets (< 576 Px) */ 
@media only screen and (max-width: 575px){
	.contentHorizontal{
		width: auto;
		margin-left: 0;
		margin-right: 0;
	}
	#headerNavigationPart .navbar-toggle-button{
		padding: 0;
	}
	#navbar{
		padding-bottom: 0px;
	}
	#headerLogo {
		height: 200%;
	}
	#headerLogo img {
		width: auto;
		height: 50%;
	}
	#headerNavigationPart .navbar-icon-links {
		flex: 1;
		width: 100%;
	}
	#headerNavigationPart #navbar-items {
		padding: 0;
	}
}

/* Small Devices / Tablets (≥ 576 Px) */ 
@media only screen and (min-width: 576px) and (max-width: 767px){
	.header{
		display: flex;
		flex-direction: row;
	}
	.header > .contentHorizontal {
		display:flex;
		justify-content: center;
		width: 100%;
	}
	#headerNavigationPart #navbar-items {
		padding: 0;
	}
}



/*Toogle navbar at < 1200px */
@media only screen and (max-width: 799px){
	.navbar-toggle-button{
		display: flex;
		margin-left: auto;
		margin-right: 32px;
		padding-top: 38px;
		padding-bottom: 26px;
		padding-right: 0px;
		background: none!important;
		border: none;
		cursor: pointer;
		text-decoration: none;
	}
	.navbar-icon-links span{
		text-transform: capitalize;
		float: left;
	}
	#headerNavigationPart .navbar-icon-links .nav-link {
		display: flex;
		flex-direction: column;
		z-index: 1;
		padding: 15px 30px;
		width: 100%;
	}
	#headerNavigationPart #navbar-items .nav-link{
		display: none;
	}
	#headerNavigationPart .navbar-icon-links {
		padding: 0;
		align-items: flex-start;
	}
	#headerNavigationPart .navbar-icon-links .nav-link{
		display: none;
		border-bottom: 1px solid #FFF;
		margin: 0;
	}
	#headerNavigationPart #hamburgerIcon{
		height: 30px;
		width: 30px;
	}
	#test-system-message-full-screen {
		display: none;	
	}
	#test-system-message-small-screen {
		color: var(--color-red5);
		font-size: 14px;
		text-align: center;
		background-color: var(--color-red60);
		width: 100%;
		position: absolute;
		left: 0px;
		top: 142px;

	}
}

/* Extra Large Devices / Desktops (≥ 1200 Px) */
@media only screen and (min-width: 800px) {
	.navbar-toggle-button {
	  display: none;
	}

	#headerNavigationPart #navbar-items{
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding: 0;
	}
	#headerNavigationPart #navbar-items .nav-link {
		padding: 0px 0px 0px 0px;
	}
	#headerNavigationPart .navbar-icon-links {
		display: none;
	}
	#headerNavigationPart #hamburgerIcon{
		height: 30px;
		width: 30px;
	}
	#headerNavigationPart .navbar-icon-links .nav-link{
		display: none;
	}
	#headerNavigationPart .nav-link.active {
		padding: 0px 0px 19px 0px;
	}
	#test-system-message-full-screen {
		color: var(--color-red5);
		background-color: var(--color-red60);
		font-size: 12px;
		text-align: center;
	}

	#test-system-message-small-screen {
        color: var(--color-red5);
        font-size: 14px;
        text-align: center;
        background-color: var(--color-red60);
        width: 100%;
        position: absolute;
        left: 0px;
        top: 142px;

    }


}

@media screen and (min-width: 576px){
		/**********Header for all size */
	#headerLogo {
    display: flex;
    padding-bottom: 16px;
    width: 140px;
	}
	#headerLogo img {
		max-height: 100%;
	}
}

@media only screen and (max-width: 575px) {
	#test-system-message-small-screen {
		color: var(--color-red5);
		background-color: var(--color-red60);
		font-size: 12px;
		text-align: center;
		left: 0px;
	}
}

ul{
	margin: 0;
}
li{
	display: flex;
	list-style-type: none;  
}

/*Part of PAGE HEADER with: --->  EN|DE, Operator, Log out <---*/
#headerNavigationPart{
    display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 80px;
}

#headerNavigationPart > ul > li{
    display: table;
    padding: 0;
}
#headerNavigationPart > ul > *:first-child > a:nth-child(2) {
    margin-left: 8px;
}
#headerNavigationPart > ul > *:last-child  > a:nth-child(2){
    margin-right: 8px;
}
#headerNavigationPart > ul > li > a:nth-child(2){
    display: table-cell;
    vertical-align: bottom;
    line-height: normal;
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-bottom: 24px !important;
}
#headerNavigationPart > ul > li > a:nth-child(2) > h5 {
    font-size: 18px;
    font-weight: normal;
    padding: 0;
    height: fit-content;
}
#headerNavigationPart > ul > li > a:hover:nth-child(2) > h5{
    font-weight: bold;
}
#headerNavigationPart > ul > li > a:nth-child(2):hover{
    border-bottom: 4px solid var(--color-blue5) !important;
    border-radius: 2px;
    background-color: var(--color-blue80);
}

#headerLogo > a > img:nth-child(2) {
  height: 22px;
  margin-top: 27px;
  width: 180px;
}

#headerLogo > a > img:nth-child(1) {
  height: 64px;
}

#headerLogo > a {
  width: 100%;
}

#headerLogoutPart {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	height: 52px;
	border-bottom: 1px solid rgba(255,255,255,0.25);
}


#language_div {
    display:flex;
}

#language_div > .language_button:hover {
    opacity: 0.5;
}
#language_div > img{
  margin-right: 16px !important;
}

#headerLogoutPart > *{
    padding-right: 16px;
    padding-left: 16px;
}
#headerLogoutPart > *:first-child{
    margin-left: 16px !important;
}
#headerLogoutPart > *:last-child {
    margin-right: 16px !important;
}
#headerLogoutPart > *:hover{
    background-color: var(--color-blue80);
    border-radius: 2px;
    font-weight: bold;
}



#headerLogoutPart .nav-link.active{
  font-weight: 600;
}
#emptyWhiteLine{
	border-left: 1px solid #FFF;
	margin: auto 2px auto 2px;
	height: 50%
}
.selectedLanguage{
  font-weight: 600;
}
/*Part of PAGE HEADER with: ---> NAV BAR <---*/

#headerNav{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
#headerNavigationPart h5{
	text-align: center;
}
#headerLogo .nav-link{
	padding: 0px !important;
}





/*DISABLING LINK CSS PROPERTIES!!!*/
#headerNavigationPart a{
  display: flex;
  flex-direction: row;
  font-family: 'bdrText', 'Courier New', Courier, monospace;
  font-weight: medium;
  font-size: 18px;
  color: var(--color-blue5);
}
#headerNavigationPart a:hover, .navbar-toggle-button:hover{
  color: #FFF;
}
#headerNavigationPart.active{
  color: #FFF;
}
#headerNavigationPart a:active, .navbar-toggle-button:hover{
  color: #FFF;
}
#headerNavigationPart a:disabled, .navbar-toggle-button:hover{
  color: #FFF;
}
#headerNavigationPart a:visited, .navbar-toggle-button:hover{
  color: #FFF;
}

#headerLogoutPart a {
  font-family: 'bdrText', 'Courier New', Courier, monospace;
  font-weight: medium;
  font-size: 14px;
  color: var(--color-blue5);
  padding: 0px 0px 0px 0px;
}
#headerLogoutPart a:hover{
  color: #FFF;
}
#headerLogoutPart a:focus{
  color: #FFF;
}
#headerLogoutPart a:active{
  color: #FFF;
}
#headerLogoutPart a:disabled{
  color: #FFF;
}
#headerLogoutPart a:visited{
  color: #FFF;
}

.sticky {
	position: fixed;
	top: -52px;
	width: 100%;
	z-index:9;
}

.sticky + .body {
	padding-top: 132px;
}