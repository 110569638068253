:root {

    /*SHADES OF BLUE*/
    --color-blue100: #000029;
    --color-blue90: #030045;
    --color-blue80: #001D6C;
    --color-blue70: #1033A2;
    --color-blue60: #0043CE;
    --color-blue50: #296EF5;
    --color-blue40: #4589FF;
    --color-blue30: #78A9FF;
    --color-blue20: #A6C8FF;
    --color-blue10: #D0E2FF;
    --color-blue5: #EEF4FD;

    /*SHADES OF GREY*/
    --color-grey100: #2D2D2D;
    --color-grey90: #3C3C3C;
    --color-grey80: #575757;
    --color-grey70: #6F6F6F;
    --color-grey60: #878787;
    --color-grey50: #A8A8A8;
    --color-grey40: #C6C6C6;
    --color-grey30: #DADADA;
    --color-grey20: #EDEDED;
    --color-grey10: #F6F6F6;
    --color-grey5: #FBFBFB;

    /*SHADES OF GREEN*/
    --color-green100: #00442C;
    --color-green90: #00543C;
    --color-green80: #007E5A;
    --color-green70: #00A073;
    --color-green60: #00AF7D;
    --color-green50: #00C387;
    --color-green40: #00D296;
    --color-green30: #33DBAB;
    --color-green20: #65E0B9;
    --color-green10: #8EDEC6;
    --color-green5: #E4F6F1;

    /*SHADES OF RED*/
    --color-red100: #420408;
    --color-red90: #520408;
    --color-red80: #750E13;
    --color-red70: #A2191F;
    --color-red60: #CA1E28;
    --color-red50: #E60037;
    --color-red40: #FA4D56;
    --color-red30: #F08A8C;
    --color-red20: #F6B9BA;
    --color-red10: #F9D0D1;
    --color-red5: #FFF2F2;

    /*SHADES OF ORANGE*/
    --color-orange100: #5C2B0E;
    --color-orange90: #8B3913;
    --color-orange80: #BA5017;
    --color-orange70: #E4650E;
    --color-orange60: #F08200;
    --color-orange50: #FFBC00;
    --color-orange40: #F7A746;
    --color-orange30: #F0C891;
    --color-orange20: #F4D7AF;
    --color-orange10: #F8E4CA;
    --color-orange5: #FCF3E8;

    /*SHADES OF ORANGE*/
    --color-yellow100: #785C14;
    --color-yellow90: #946913;
    --color-yellow80: #AF7B12;
    --color-yellow70: #C7B210;
    --color-yellow60: #E49800;
    --color-yellow50: #F9AF05;
    --color-yellow40: #FDC300;
    --color-yellow30: #FCD25F;
    --color-yellow20: #FAE18B;
    --color-yellow10: #FDE9AD;
    --color-yellow5: #FFF6DC;

    /*SHADES OF PURPLE*/
    --color-purple100: #510087;
    --color-purple90: #6400AB;
    --color-purple80: #7800BE;
    --color-purple70: #8C00D2;
    --color-purple60: #9605DC;
    --color-purple50: #AA60E0;
    --color-purple40: #BA7DE5;
    --color-purple30: #C694E9;
    --color-purple20: #D2ABEE;
    --color-purple10: #DFC3F3;
    --color-purple5: #EEE8F3;

    --color-white100: #FFFFFF;


}