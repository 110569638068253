/* bdrText 400 */
@font-face {
  font-family: 'bdrText';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    url('../fonts/bdrText-Regular.otf') format('opentype'),
    url('../fonts/bdrText-Regular.ttf') format('truetype'),
    url('../fonts/bdrText-Regular.woff') format('woff'),
    url('../fonts/bdrText-Regular.woff2') format('woff2');
}

/* bdrText Italic 400 */
@font-face {
  font-family: 'bdrText';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    url('../fonts/bdrText-RegularItalic.otf') format('opentype'),
    url('../fonts/bdrText-RegularItalic.ttf') format('truetype'),
    url('../fonts/bdrText-RegularItalic.woff') format('woff'),
    url('../fonts/bdrText-RegularItalic.woff2') format('woff2');
}

/* bdrText 500 */
@font-face {
  font-family: 'bdrText';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    url('../fonts/bdrText-Medium.otf') format('opentype'),
    url('../fonts/bdrText-Medium.ttf') format('truetype'),
    url('../fonts/bdrText-Medium.woff') format('woff'),
    url('../fonts/bdrText-Medium.woff2') format('woff2');
}

/* bdrText Italic 500 */
@font-face {
  font-family: 'bdrText';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    url('../fonts/bdrText-MediumItalic.otf') format('opentype'),
    url('../fonts/bdrText-MediumItalic.ttf') format('truetype'),
    url('../fonts/bdrText-MediumItalic.woff') format('woff'),
    url('../fonts/bdrText-MediumItalic.woff2') format('woff2');
}


/* bdrText 600 */
@font-face {
  font-family: 'bdrText';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    url('../fonts/bdrText-SemiBold.otf') format('opentype'),
    url('../fonts/bdrText-SemiBold.ttf') format('truetype'),
    url('../fonts/bdrText-SemiBold.woff') format('woff'),
    url('../fonts/bdrText-SemiBold.woff2') format('woff2');
}

/* bdrText Italic 600 */
@font-face {
  font-family: 'bdrText';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    url('../fonts/bdrText-SemiBoldItalic.otf') format('opentype'),
    url('../fonts/bdrText-SemiBoldItalic.ttf') format('truetype'),
    url('../fonts/bdrText-SemiBoldItalic.woff') format('woff'),
    url('../fonts/bdrText-SemiBoldItalic.woff2') format('woff2');
}

/* bdrText 700 */
@font-face {
  font-family: 'bdrText';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    url('../fonts/bdrText-Bold.otf') format('opentype'),
    url('../fonts/bdrText-Bold.ttf') format('truetype'),
    url('../fonts/bdrText-Bold.woff') format('woff'),
    url('../fonts/bdrText-Bold.woff2') format('woff2');
}

/* bdrText Italic 700 */
@font-face {
  font-family: 'bdrText';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    url('../fonts/bdrText-BoldItalic.otf') format('opentype'),
    url('../fonts/bdrText-BoldItalic.ttf') format('truetype'),
    url('../fonts/bdrText-BoldItalic.woff') format('woff'),
    url('../fonts/bdrText-BoldItalic.woff2') format('woff2');
}